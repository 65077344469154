<template>
  <div class="m">
    <div class="m__header">
      <h1 class="m__title">Меню</h1>
      <button class="m__close" type="button" @click="$emit('close')">
        <IconComponent category="linear" name="add" />
      </button>
    </div>
    <div class="m__body">
      <ul class="m__links">
        <li v-for="(link, i) in links.filter((l) => l.show)" :key="i">
          <router-link :to="link.route">{{ link.title }}</router-link>
        </li>
        <template v-if="$store.state.user">
          <li v-if="!this.$route.path.includes('/account')">
            <router-link :to="{ name: 'account' }">Личный кабинет</router-link>
          </li>
          <li>
            <a href="#" @click.prevent="logout">Выйти</a>
          </li>
        </template>
      </ul>
      <button type="button" class="btn btn--main" v-if="!$store.state.user" @click="login">Войти</button>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import axios from "axios";
import LoginPhoneModal from "components/modals/components/LoginPhoneModal.vue";

export default {
  name: "RegisterModalComponent",
  data() {
    return {
      loading: false,
      form: {
        email: {
          errors: [],
          value: null,
        },
        fio: {
          errors: [],
          value: null,
        },
        phone: {
          errors: [],
          value: null,
        },
        password_confirmation: {
          errors: [],
          value: null,
        },
        password: {
          show: false,
          errors: [],
          value: null,
        },
      },
    };
  },
  computed: {
    links() {
      return [
        {
          title: "Преимущества",
          route: {
            name: "home",
            hash: "#advantages",
          },
          show: !this.$route.path.includes("/account"),
        },
        {
          title: "Регионы",
          route: {
            name: "home",
            hash: "#subjects",
          },
          show: !this.$route.path.includes("/account"),
        },
        {
          title: "FAQ",
          route: {
            name: "home",
            hash: "#faq",
          },
          show: !this.$route.path.includes("/account"),
        },
        {
          title: "Главная",
          route: {
            name: "home",
          },
          show: this.$route.path.includes("/account"),
        },
        {
          title: "Регионы",
          route: {
            name: "account",
          },
          show: this.$route.path.includes("/account"),
        },
        {
          title: "Помощь",
          route: {
            name: "account_faq",
          },
          show: this.$route.path.includes("/account"),
        },
      ];
    },
  },
  methods: {
    login() {
      this.$store.state._modals.push({
        component: LoginPhoneModal,
      });
    },
    logout() {
      if (confirm("Вы уверены?")) {
        axios({
          method: "post",
          url: "/token",
          data: {
            token: undefined,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }).then(() => {
          this.$store.state.user = null;
          if (this.$route.name !== "home") {
            this.$router.push({
              name: "home",
            });
          }
        });
      }
    },
  },
  components: {
    IconComponent,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/modal.styl"
.m__links {
  padding: 0;
  margin: 0;
  list-style: none;
  display: grid;
  grid-gap: 10px;

  a {
    text-decoration none
    font-weight: 400;
    font-size: 1.125em;
    line-height: 130%;
    color: var(--main);
  }
}
</style>
