<template>
  <footer class="footer">
    <IconComponent category="default" name="logo-square" />
    <ul class="footer__nav">
      <li>
        <router-link :to="{ name: 'home', hash: '#advantages' }">Преимущества</router-link>
      </li>
<!--      <li>-->
<!--        <router-link :to="{ name: 'home', hash: '#tariffs' }">Цены</router-link>-->
<!--      </li>-->
      <li>
        <router-link :to="{ name: 'home', hash: '#faq' }">FAQ</router-link>
      </li>
      <li>
        <a href="/files/terms.pdf" target="_blank">Политика конфиденциальности</a>
      </li>
      <li>
        <a href="/files/services.pdf" target="_blank">Порядок возврата средств</a>
      </li>
    </ul>
    <div class="footer__apps">
      <a
        href="https://apps.apple.com/tr/app/tuda-suda/id6443678429"
        target="_blank"
        rel="nofollow"
        title="App Store"
      >
        <IconComponent category="default" name="app-store" />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.app.tudasuda&hl=ru&gl=US"
        target="_blank"
        rel="nofollow"
        title="Google Play"
      >
        <IconComponent category="default" name="google-play" />
      </a>
    </div>
    <span class="footer__copy">
      <a href="mailto:dozalab@yandex.ru">dozalab@yandex.ru</a>
      <span>ИНН: 732593978907</span>
      {{ year }} © ИП "Долгих Алексей Станиславович"
    </span>
  </footer>
</template>
<script>
import IconComponent from "components/IconComponent.vue";
import moment from "moment";

export default {
  name: "FooterComponent",
  data() {
    return {
      year: moment().format("YYYY"),
    };
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
.footer {
  display grid
  grid-gap 30px
  justify-content center
  align-items center
  padding 100px 0

  > .icon {
    width 205px
    height 110px
    margin auto
  }

  &__nav {
    margin 0
    padding 0
    list-style none
    display flex
    align-items center
    justify-content center
    gap 50px
    +below(768px) {
      gap 25px
      flex-wrap wrap
    }

    a {
      font-size: 1.125em;
      color: var(--main);
      text-decoration none
    }
  }

  &__apps {
    display flex
    align-items center
    justify-content center
    gap 10px
    margin auto

    a {
      width auto
      height 56px
      +below(768px) {
        height: 40px;
      }

      .icon {
        width 100%
        height 100%
      }
    }
  }

  &__copy {
    font-size: 1em;
    line-height: 145%;
    text-align: center;
    color: var(--main);
    display grid
    gap 10px

    a {
      color: var(--main);
    }
  }
}
</style>
