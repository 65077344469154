<template>
  <div id="app">
    <HeaderComponent />
    <Transition mode="out-in" name="page">
      <RouterView :key="$route.fullPath" />
    </Transition>
    <FooterComponent />
    <ModalComponent />
  </div>
</template>

<script>
import HeaderComponent from "components/HeaderComponent.vue";
import FooterComponent from "components/FooterComponent.vue";
import ModalComponent from "components/modals/index.vue";

export default {
  name: "App",
  components: { ModalComponent, FooterComponent, HeaderComponent },
  metaInfo: {
    title: "Планируй свое путешествие быстро и удобно. Местный гид в твоем кармане",
    titleTemplate: "%s | Туда - Сюда",
  },
};
</script>

<style lang="stylus">
@import 'styles/style.styl';
</style>
