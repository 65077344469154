import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default function createRouter() {
  const routes = [
    {
      path: "/",
      name: "home",
      component: () => import("./views/home/index.vue"),
    },
    {
      path: "/account",
      name: "account",
      component: () => import("./views/auth/region/index.vue"),
      meta: {
        authRequired: true,
      },
    },
    {
      path: "/account/faq",
      name: "account_faq",
      component: () => import("./views/auth/faq/index.vue"),
      meta: {
        authRequired: true,
      },
    },
    {
      path: "/buying-on-apple",
      name: "apple",
      component: () => import("./views/static/buying-on-apple.vue"),
    },
    {
      path: "/thanks/:id?",
      name: "thanks",
      component: () => import("./views/thanks/index.vue"),
    },
    {
      path: "*",
      name: "notfound",
      component: () => import("./views/PageNotFound.vue"),
    },
  ];
  return new Router({
    mode: "history",
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      }
      if (to.hash) {
        return {
          selector: to.hash,
          behavior: "smooth",
        };
      }
      return { x: 0, y: 0 };
    },
    fallback: true,
    routes,
  });
}
