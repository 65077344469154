<template>
  <header class="header" :class="{ 'header--absolute': $route.name === 'home' }">
    <router-link
      :to="{ name: 'home' }"
      class="header__logo"
      :class="{ 'header__logo-absolute': $route.name !== 'home' }"
      title="My Projects"
    >
      <IconComponent :name="windowSize > 1200 ? 'logo-no-m' : 'logo-simple'" category="default" />
    </router-link>
    <nav class="header__nav">
      <ul class="header__links">
        <li v-for="(link, i) in links.filter((l) => l.show)" :key="i">
          <router-link :to="link.route">{{ link.title }}</router-link>
        </li>
      </ul>
    </nav>
    <button type="button" class="btn btn--main header__mobile" @click="showMenu">
      <IconComponent category="linear" name="menu" />
    </button>
    <button type="button" class="btn btn--main" v-if="!$store.state.user" @click="login">Войти</button>
    <div class="header__user" v-else>
      <router-link :to="{ name: 'account' }">Личный кабинет</router-link>
      <a href="#" @click.prevent="logout">Выйти</a>
    </div>
  </header>
</template>

<script>
import IconComponent from "./IconComponent.vue";
// import LoginModal from "components/modals/components/LoginModal.vue";
import MenuModal from "components/modals/components/MenuModal.vue";
import axios from "axios";
import LoginPhoneModal from "components/modals/components/LoginPhoneModal.vue";

export default {
  name: "HeaderComponent",
  computed: {
    user() {
      return this.$store.state.user;
    },
    windowSize() {
      return this.$store.state._window_size;
    },
    links() {
      return [
        {
          title: "Преимущества",
          route: {
            name: "home",
            hash: "#advantages",
          },
          show: this.$route.name === "home",
        },
        {
          title: "FAQ",
          route: {
            name: "home",
            hash: "#faq",
          },
          show: this.$route.name === "home",
        },
        {
          title: "Главная",
          route: {
            name: "home",
          },
          show: this.$route.path.includes("/account"),
        },
        {
          title: "Регионы",
          route: {
            name: "account",
          },
          show: this.$route.path.includes("/account"),
        },
        {
          title: "Помощь",
          route: {
            name: "account_faq",
          },
          show: this.$route.path.includes("/account"),
        },
      ];
    },
  },
  methods: {
    login() {
      this.$store.state._modals.push({
        component: LoginPhoneModal,
      });
    },
    showMenu() {
      this.$store.state._modals.push({
        component: MenuModal,
      });
    },
    logout() {
      if (confirm("Вы уверены?")) {
        axios({
          method: "post",
          url: "/token",
          data: {
            token: undefined,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }).then(() => {
          this.$store.state.user = null;
          if (this.$route.name !== "home") {
            this.$router.push({
              name: "home",
            });
          }
        });
      }
    },
  },
  components: {
    IconComponent,
  },
};
</script>

<style lang="stylus">
.header {
  display flex
  justify-content flex-start
  align-items center
  padding 45px 100px
  +below(1310px) {
    padding 40px
  }
  +below(800px) {
    .btn:not(.header__mobile) {
      display none
    }
  }
  +below(768px) {
    padding 20px
    //display grid
    //grid-template-columns auto 1fr
    //grid-gap 20px
    gap 20px
  }
  +below(560px) {
    padding 16px 20px
  }

  &--absolute {
    absolute left top
    width 100%
    z-index 1
    +below(560px) {
      align-items flex-start
    }
  }

  &--relative {
    +below(560px) {
      align-items center
    }
  }

  &__mobile.btn {
    margin-left auto
    padding 10px
    +above(801px) {
      display none
    }

    .icon {
      width 20px
      height 20px

      svg path {
        stroke var(--white)
      }
    }
  }

  &__logo {
    height 100px
    width 100px
    flex-shrink 0
    display inline-flex
    align-items center
    justify-content center

    +below(1200px) {
      width 40px
    }

    +below(560px) {
      align-items flex-start
    }

    .icon {
      width 100%
    }
  }

  &__logo-absolute{
    +below(560px) {
      align-items center
    }
  }

  &__nav {
    display flex
    align-items center
    padding 0 70px
    width 100%
    height 100%
    +below(1200px) {
      padding 0 20px
    }
    +below(800px) {
      display none
    }
  }

  &__user {
    display flex
    align-items center
    gap 30px
    flex-shrink 0
    +below(800px) {
      display none
    }

    a {
      font-weight: 400;
      font-size: 1.125em;
      line-height: 130%;
      text-align: center;
      color: var(--main);
      text-decoration none
    }
  }

  &__links {
    width 100%
    display flex
    align-items center
    justify-content flex-start
    margin 0
    padding: 0;
    +below(800px) {
      display none
    }

    li {
      display inline-flex
      align-items center
      justify-content center

      a {
        font-weight: 400;
        font-size: 1.125em;
        line-height 100%
        color: var(--main);
        text-decoration none
        padding 15px 25px
        transition var(--transition)
        +below(1200px) {
          padding 10px 15px
        }
        +below(768px) {
          padding 10px
        }

        &.router-link-exact-active {
          color: var(--green);
        }
      }
    }
  }
}
</style>
