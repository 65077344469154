<template>
  <div class="loading-indicator">
    <span>{{ title }}</span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      style="margin: auto; background: rgba(224, 102, 102, 0); display: block"
      width="250px"
      height="250px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="#ffffff"
        stroke-width="14"
        r="40"
        stroke-dasharray="188.49555921538757 64.83185307179586"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="0.6666666666666666s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        ></animateTransform>
      </circle>
    </svg>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

/**
 * Текст "Подождите" с динамическими точками
 */
export default {
  name: "LoadingIndicator",
  components: { IconComponent },
  props: {
    title: {
      type: String,
      default: "Подождите, идет загрузка",
    },
  },
  data() {
    return {
      dots: 0,
      interval: null,
    };
  },
  created() {
    this.interval = setInterval(() => {
      if (this.dots >= 3) {
        this.dots = 0;
      } else {
        this.dots += 1;
      }
    }, 100);
  },
};
</script>

<style lang="stylus">
.loading-indicator {
  display inline-flex
  align-items center
  position relative
  gap 10px

  & svg{
    width 20px
    height 20px
  }
}
</style>
