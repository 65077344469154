import Vue from "vue";
import Vuex from "vuex";
import BUY from "gql/SubscriptionBuy.graphql";

Vue.use(Vuex);
export default function createStore(state) {
  return new Vuex.Store({
    state() {
      return (
        state || {
          _modals: [],
          tariffs: [],
          user: null,
          _window_size: 1920,
        }
      );
    },
    actions: {
      async BUY({ state }, { apollo, id, promocode }) {
        await apollo
          .mutate({
            mutation: BUY,
            variables: {
              id: id,
              promocode: promocode,
            },
            context: {
              headers: {
                Authorization: "Bearer " + state.apollo_token,
              },
            },
          })
          .then(({ data }) => {
            if (data && data.OrderCreate) {
              location.replace(data.OrderCreate.payment_url);
            }
          });
      },
    },
  });
}
